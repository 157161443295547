<template>
  <v-container class="reset-password-request">
    <v-card class="elevation-0 pa-6">
      <div class="d-flex align-center flex-column mb-3">
        <CategoryTitle :category="category" />
        <div v-if="message">
          <h3
            v-if="isResetFromRegistration"
            class="font-weight-regular text-uppercase text-center mb-5"
            v-html="$te(message) || message"
          />
          <h3 class="font-weight-regular text-uppercase text-center mb-5" />
        </div>
        <h3
          class="font-weight-regular text-uppercase text-center"
          v-html="
            $t(
              isResetFromRegistration
                ? 'resetPassword.fromRegistrationText'
                : 'resetPassword.defaultText'
            )
          "
        />
      </div>
      <v-card-text>
        <v-form v-on:submit.prevent ref="form" v-model="valid">
          <v-row no-gutters justify="center">
            <v-col cols="12" sm="7">
              <v-text-field
                :label="`${$t('resetPassword.label')}*`"
                v-model="value"
                required
                outlined
                :rules="validationRules"
                dense
                clearable
                @keyup.enter="handleResetPasswordRequest"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters class="mt-2" justify="center">
          <v-btn
            @click="handleResetPasswordRequest"
            class="reset-button"
            color="primary"
            large
            depressed
            width="200"
            :loading="loading"
            :disabled="!valid"
            >{{ $t("resetPassword.button") }}</v-btn
          >
        </v-row>
      </v-card-actions>
      <i18n
        path="login.contactUs"
        tag="div"
        class="default--text text-center mt-6 font-weight-bold contact-number"
      >
        <template v-slot:link>
          <a
            href="tel:0442325407"
            target="_self"
            class="font-weight-bold default--text text-decoration-underline text-center"
            :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
          >
            0442 - 325407
          </a>
        </template>
      </i18n>
    </v-card>

    <v-card v-if="!showForm" class="elevation-0">
      <div class="d-flex align-center flex-column mb-3">
        <h1 class="reset-title">RECUPERA PASSWORD</h1>
      </div>
      <v-spacer></v-spacer>
      <v-card-text class="response-messages">
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions>
        <div
          class="d-flex flex-column align-center mt-2 register-wrapper"
        ></div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style scoped lang="scss">
.reset-password-request {
  .response-messages {
    .v-alert {
      &.v-alert--outlined {
        border: none;
      }
    }
  }
  .v-messages__message {
    line-height: 17px !important;
  }
}
</style>
<script>
import UserService from "~/service/userService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";
import { requiredValue } from "~/validator/validationRules";
import { isEmailOrValidCf } from "@/tosanoValidators";
import isEmpty from "lodash/isEmpty";
import category from "~/mixins/category";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      value: null,
      isResetFromRegistration: false,
      message: null,
      valueType: null,
      valid: false,
      loading: false,
      validationRules: [requiredValue(), isEmailOrValidCf()],
      response: {},
      showForm: true
    };
  },
  methods: {
    async handleResetPasswordRequest(e) {
      let _this = this;
      e.preventDefault();
      _this.response = {};
      if (_this.valid) {
        let isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,11}$/.test(
          _this.value
        );
        let isFiscalCode = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
          _this.value.toUpperCase()
        );
        _this.loading = true;
        try {
          const res = await UserService.getResetPasswordToken(
            isEmail ? _this.value : null,
            isFiscalCode ? _this.value.toUpperCase() : null
          );
          if (res.response) {
            //vm.response = res.response;
            _this.$router.push({ name: "ResetPasswordSent" });
          }
        } catch (err) {
          if (err.response) {
            _this.response = err.response.data.response;
          } else {
            _this.response = {
              errors: [
                { error: "Utente non trovato, verificare i dati inseriti." }
              ],
              infos: [],
              status: 0
            };
          }
        } finally {
          _this.showForm = false;
          _this.loading = false;
        }
      }
    }
  },
  created() {
    if (!isEmpty(this.$route.params)) {
      this.value = this.$route.params.fiscalCode.toUpperCase();
      this.isResetFromRegistration = this.$route.params.isResetFromRegistration;
      this.message = this.$route.params.message;
    }
  }
};
</script>
