var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"reset-password-request"},[_c('v-card',{staticClass:"elevation-0 pa-6"},[_c('div',{staticClass:"d-flex align-center flex-column mb-3"},[_c('CategoryTitle',{attrs:{"category":_vm.category}}),(_vm.message)?_c('div',[(_vm.isResetFromRegistration)?_c('h3',{staticClass:"font-weight-regular text-uppercase text-center mb-5",domProps:{"innerHTML":_vm._s(_vm.$te(_vm.message) || _vm.message)}}):_vm._e(),_c('h3',{staticClass:"font-weight-regular text-uppercase text-center mb-5"})]):_vm._e(),_c('h3',{staticClass:"font-weight-regular text-uppercase text-center",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            _vm.isResetFromRegistration
              ? 'resetPassword.fromRegistrationText'
              : 'resetPassword.defaultText'
          )
        )}})],1),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('resetPassword.label')}*`,"required":"","outlined":"","rules":_vm.validationRules,"dense":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleResetPasswordRequest.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-btn',{staticClass:"reset-button",attrs:{"color":"primary","large":"","depressed":"","width":"200","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.handleResetPasswordRequest}},[_vm._v(_vm._s(_vm.$t("resetPassword.button")))])],1)],1),_c('i18n',{staticClass:"default--text text-center mt-6 font-weight-bold contact-number",attrs:{"path":"login.contactUs","tag":"div"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('a',{staticClass:"font-weight-bold default--text text-decoration-underline text-center",class:_vm.$vuetify.breakpoint.xs ? 'default--text' : '',attrs:{"href":"tel:0442325407","target":"_self"}},[_vm._v(" 0442 - 325407 ")])]},proxy:true}])})],1),(!_vm.showForm)?_c('v-card',{staticClass:"elevation-0"},[_c('div',{staticClass:"d-flex align-center flex-column mb-3"},[_c('h1',{staticClass:"reset-title"},[_vm._v("RECUPERA PASSWORD")])]),_c('v-spacer'),_c('v-card-text',{staticClass:"response-messages"},[_c('ResponseMessage',{attrs:{"response":_vm.response}})],1),_c('v-card-actions',[_c('div',{staticClass:"d-flex flex-column align-center mt-2 register-wrapper"})])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }